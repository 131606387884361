footer {
    position: fixed;
    bottom:0;
    width: 100%;
    height: 50px;
    background: white;
    border-top: 1px solid gray;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footerContent {
    font-family: 'Ubuntu';
    font-weight: bold;
}