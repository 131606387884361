nav {
    position: fixed;
    top: 0;
    width: 100%;
    height: 60px;
    background: white;
    border-bottom: 1px solid gray;
}

.btn {
    display: none;
    position: absolute;
    right: 10px;
    top: 7px;
    padding: 5px;
    color: #000;
    font-size: 18px;
}

.proposalWizardButtonContainer {
    border: solid 1px grey;
    border-radius: 15px;
    display: block;
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 6px;
    color: #000;
    font-size: 20px;
    background-color: white;
}

.proposalWizardButtonText {
    display: 'flex';
    align-items: 'center';
    padding-left: 10;
    padding-right: 10;
    padding-bottom: 8;
    padding-top: 0px;
}

.proposalWizardButtonIcon {
    display: none;
}

@media screen and (max-width: 500px){
    .proposalWizardButtonText {
        display: none;
    }
    .proposalWizardButtonIcon {
        display: block;
    }
    .proposalWizardButtonContainer {
        padding-top: 4px;
        padding-bottom: 0px;
        padding-left: 4px;
        padding-right: 4px;
        top: 6px;
    }
}