.content {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  font-family: 'Ubuntu';
  margin: 30px;
}

.content > span {
  /* border: 1px red solid; */

  margin: 5px;  /* and that, will result in a 10px gap */
}
